/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

export default {
  transparent: 'rgba(0,0,0,0)',
  // Example colors:
  black: '#000000',
  red: 'red',
  grey: '#555555',
  white: '#ffffff',
  text: '#212529',
  primary: '#007bff',
  success: '#28a745',
  error: '#dc3545',
  inputColor: 'rgb(85,85,85)',
  snackbarError: '#ff0000',
  snackbarNormal: 'green'
}
