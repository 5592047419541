/**
 * This file defines the base application styles.
 *
 * Use it to define generic component styles (e.g. the default text styles, default button styles...).
 */
import Colors from './Colors'
import Fonts from './Fonts'

export default {
  button: {
    backgroundColor: Colors.primary,
  },
  textInput: {
    ...Fonts.normal,
    height: 40,
    borderRadius: 4,
    paddingHorizontal: 10,
    color: '#000',
    backgroundColor: '#f4f4f4',
    borderColor: '#8f8f8f',
    borderWidth: 1,
    outline: 'none'
  },
}
