import React, { useEffect, useState } from 'react'
import {
  ActivityIndicator,
  ImageBackground,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import { Button } from 'react-native-paper'
import { useDispatch } from 'react-redux'
import { ApplicationStyles, Helpers } from '../../Theme'

import { Buffer } from 'buffer'
import { Camera } from 'expo-camera'
import AsyncStorage from '@react-native-community/async-storage'
import GlobalActions from '../../Stores/Global/Actions'
import PermissionsService from '../../Services/PermissionsService'
import NavigationService from '../../Services/NavigationService'
import mbBackground from '../../Assets/Images/loadingbg.png'
import btnBg from '../../Assets/Images/btn_bg@3x.png'
import { redeemService } from '../../Services/RedeemService'

const CameraScreen = () => {
  const dispatch = useDispatch()

  const [scanQR, setScanQR] = useState(false)
  const [eventName, setEventName] = useState('')
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)

  const requestCameraPermissions = async () => {
    const permission = await PermissionsService.getCameraSingle()
    if (permission) {
      setScanQR(true)
    } else {
      dispatch(
        GlobalActions.snackbar({
          visible: true,
          variant: 'error',
          message: '無法取得相機權限',
        })
      )
    }
  }

  const onCodeInputChange = (value) => {
    setCode(value)
  }

  const handleSubmit = async () => {
    const eventId = await AsyncStorage.getItem('eventId')
    redeemService
      .getUserByCode({ eventId: eventId, code: code })
      .then((data) => {
        setLoading(true)
        NavigationService.navigate('TicketByCode', {
          username: data.data.name,
          title: data.data.title,
          code: code,
        })
      })
      .catch((error) => {
        if (error.status === 404 || error.status === 422) {
          dispatch(
            GlobalActions.snackbar({
              visible: true,
              variant: 'error',
              message: '票券核銷碼輸入錯誤，請重新輸入',
            })
          )
          return
        }
        let errorCode = null
        if (error.data.errors) {
          errorCode = error.data.errors[0].title
        }
        if (errorCode === 'error.events-tickets.ticket-not-exist') {
          dispatch(
            GlobalActions.snackbar({
              visible: true,
              variant: 'error',
              message: '票券核銷碼輸入錯誤，請重新輸入',
            })
          )
          return
        }
        dispatch(
          GlobalActions.snackbar({
            visible: true,
            variant: 'error',
            message: '驗證系統異常',
          })
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onBarCodeScanned = (object) => {
    var url = object.data.split('?')[0]
    var search = object.data.split('?')[1]

    // var url = "https://event.member-beta.mercedes-benz.com.tw?ticket=eyJxckNvZGVTdHJpbmciOiJleUpwZGlJNklrcGtVRmt4TDI1eVpIVlZkVU5PU1VoS2NGY3hOSGM5UFNJc0luWmhiSFZsSWpvaWFVcHJTR1ZRWm5CblpsTkZXVlYxWnpoa09ITlNkejA5SWl3aWJXRmpJam9pTlRneFlqRXlNR1ZqWkdGbU1UTXhPVGd5T1RjNE9XUTBNV1UwWTJZek5ERTJNR1l5TTJGbE1USXlNbVk1TWpWaFlXWmlNRGczTXpBeU5tUmpNalkzTnlKOSIsInVzZXIiOnsibmFtZSI6IlBlbmcgTWFycyIsInRpdGxlIjoiTXIuIn19".split('?')[0]
    // var search = "https://event.member-beta.mercedes-benz.com.tw?ticket=eyJxckNvZGVTdHJpbmciOiJleUpwZGlJNklrcGtVRmt4TDI1eVpIVlZkVU5PU1VoS2NGY3hOSGM5UFNJc0luWmhiSFZsSWpvaWFVcHJTR1ZRWm5CblpsTkZXVlYxWnpoa09ITlNkejA5SWl3aWJXRmpJam9pTlRneFlqRXlNR1ZqWkdGbU1UTXhPVGd5T1RjNE9XUTBNV1UwWTJZek5ERTJNR1l5TTJGbE1USXlNbVk1TWpWaFlXWmlNRGczTXpBeU5tUmpNalkzTnlKOSIsInVzZXIiOnsibmFtZSI6IlBlbmcgTWFycyIsInRpdGxlIjoiTXIuIn19".split('?')[1]

    if (url && url.search(window.location.hostname) != -1 && search) {
      const params = new URLSearchParams(search)
      const ticket = params.get('ticket')
      const ticketJson = Buffer.from(ticket, 'base64').toString('utf8')
      const ticketJsonParse = JSON.parse(ticketJson)
      setScanQR(false)
      NavigationService.navigate('Ticket', {
        title: ticketJsonParse.title,
        user: ticketJsonParse.user,
        qrCodeString: ticketJsonParse.qrCodeString,
      })
    } else {
      dispatch(
        GlobalActions.snackbar({
          visible: true,
          variant: 'error',
          message: '錯誤的 QR Code',
        })
      )
    }
  }
  const getEventNameFromLocal = async () => {
    var name = await AsyncStorage.getItem('eventName')
    setEventName(name)
  }

  const changeEvent = () => {
    dispatch(
      GlobalActions.dialogConfirm({
        confirmBol: true,
        visible: true,
        title: '',
        okText: '確定',
        okAction: async (dispatch) => {
          await AsyncStorage.removeItem('verification')
          await AsyncStorage.removeItem('eventName')
          dispatch(GlobalActions.dialogConfirm({ visible: false }))
          NavigationService.navigateAndReset('Verification')
        },
        message: '確認前往更換活動？',
        component: null,
      })
    )
  }

  useEffect(() => {
    requestCameraPermissions()
    getEventNameFromLocal()
  }, [])

  return (
    <ImageBackground
      imageStyle={{
        resizeMode: 'cover',
      }}
      source={mbBackground}
      style={Helpers.fillColMain}
    >
      <View style={[Helpers.fillCenter, Helpers.rowMain]}>
        <View
          style={[
            Helpers.center,
            { zIndex: 100, position: 'absolute', width: '100%', height: '100%' },
          ]}
        >
          <View style={[Helpers.center, { width: '80%', marginBottom: 50 }]}>
            <Text style={{ color: '#fff', fontSize: 16 }}>{eventName}</Text>
          </View>
          {scanQR ? (
            <>
              <View style={[Helpers.center, { width: 250, height: 250 }]}>
                <Camera
                  barCodeScannerSettings={{ barCodeTypes: ['qr'] }}
                  onBarCodeScanned={onBarCodeScanned}
                  style={{ width: 250, height: 250, backgroundColor: '#000' }}
                >
                  <View
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: 100,
                      height: 100,
                      borderTopWidth: 2,
                      borderLeftWidth: 2,
                      borderColor: 'rgb(0, 136, 198)',
                    }}
                  ></View>
                  <View
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      width: 100,
                      height: 100,
                      borderTopWidth: 2,
                      borderRightWidth: 2,
                      borderColor: 'rgb(0, 136, 198)',
                    }}
                  ></View>
                  <View
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: 100,
                      height: 100,
                      borderBottomWidth: 2,
                      borderLeftWidth: 2,
                      borderColor: 'rgb(0, 136, 198)',
                    }}
                  ></View>
                  <View
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      width: 100,
                      height: 100,
                      borderBottomWidth: 2,
                      borderRightWidth: 2,
                      borderColor: 'rgb(0, 136, 198)',
                    }}
                  ></View>
                </Camera>
              </View>
              <Text style={{ color: '#fff', fontSize: 16, marginTop: 20 }}>請掃描票券QRcode</Text>
              <Text style={{ color: '#fff', fontSize: 16, marginTop: 16 }}>或輸入票券核銷碼</Text>
              <TextInput
                autoCapitalize="none"
                placeholder="請輸入票券核銷碼"
                style={[ApplicationStyles.textInput, { width: 250, marginTop: 8 }]}
                value={code}
                onChangeText={onCodeInputChange}
              />
              <ImageBackground
                imageStyle={{
                  resizeMode: 'cover',
                  borderRadius: 4,
                  opacity: loading ? 0.6 : 1,
                }}
                source={btnBg}
                style={[Helpers.rowCenter, { width: 250, height: 38, marginTop: 16 }]}
              >
                <TouchableOpacity
                  disabled={loading}
                  style={[Helpers.fillRowCenter, { height: 38 }]}
                  onPress={handleSubmit}
                >
                  {loading && (
                    <ActivityIndicator style={{ marginRight: 8 }} color="#fff" size="small" />
                  )}
                  <View style={Helpers.rowCenter}>
                    <Text style={{ color: '#fff', fontSize: 14 }}>送出</Text>
                  </View>
                </TouchableOpacity>
              </ImageBackground>
            </>
          ) : (
            <Text style={{ color: '#fff', fontSize: 16, marginTop: 20 }}>請開啟相機相關權限</Text>
          )}
          <Button
            color="#fff"
            style={{ position: 'absolute', bottom: 10, zIndex: 99 }}
            onPress={changeEvent}
          >
            更換活動
          </Button>
        </View>
      </View>
    </ImageBackground>
  )
}

export default CameraScreen
