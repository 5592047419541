import { put, call } from 'redux-saga/effects'
import NavigationService from '../Services/NavigationService'
import AsyncStorage from '@react-native-community/async-storage';

/**
 * The startup saga is the place to define behavior to execute when the application starts.
 */
export function* startup() {
  // When those operations are finished we redirect to the main screen
  var verification =  yield call(AsyncStorage.getItem, 'verification')
  if (verification) {
    NavigationService.navigateAndReset('Camera')
  }
  else {
    NavigationService.navigateAndReset('Verification')
  }
}
