import { takeLatest, all } from 'redux-saga/effects'
import { RedeemTypes } from '../Stores/Redeem/Actions'
import { StartupTypes } from '../Stores/Startup/Actions'
import { verify, redeem } from './RedeemSaga'
import { startup } from './StartupSaga'

export default function* root() {
  yield all([
    /**
     * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
     */
    // Run the startup saga when the application starts
    takeLatest(StartupTypes.STARTUP, startup),
    // Call `fetchUser()` when a `FETCH_USER` action is triggered
    takeLatest(RedeemTypes.VERIFY, verify),
    takeLatest(RedeemTypes.REDEEM, redeem),
  ])
}
