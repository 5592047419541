import React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'

import NavigationService from '../Services/NavigationService'

import CameraScreen from '../Containers/CameraScreen/CameraScreen'
import TicketScreen from '../Containers/TicketScreen/TicketScreen'
import VerificationScreen from '../Containers/VerificationScreen/VerificationScreen'
import SplashScreen from '../Containers/SplashScreen/SplashScreen'
import TicketByCodeScreen from '../Containers/TicketScreen/TicketByCodeScreen'

const Stack = createStackNavigator();

function MyStack() {
  return (
    <Stack.Navigator initialRouteName="Splash" headerMode="none">
      <Stack.Screen name="Splash" component={SplashScreen} />
      <Stack.Screen name="Camera" component={CameraScreen} />
      <Stack.Screen name="Ticket" component={TicketScreen} />
      <Stack.Screen name="TicketByCode" component={TicketByCodeScreen} />
      <Stack.Screen name="Verification" component={VerificationScreen} />
    </Stack.Navigator>
  );
}

const AppNavigation = () => {
  return (
    <NavigationContainer ref={(navigatorRef) => NavigationService.setTopLevelNavigator(navigatorRef)} >
      {
        MyStack()
      }
    </NavigationContainer>
  );
}

export default AppNavigation;
