import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View} from 'react-native'
import { Helpers, Colors, Fonts, Metrics } from '../Theme'
import { Provider as PaperProvider,  Snackbar, Text } from 'react-native-paper'
import GlobalActions from '../Stores/Global/Actions'

const SnackbarCustom = () => {
  const dispatch = useDispatch();
  const snackbarSetting = useSelector((state) => state.global.snackbar);

  return (
    <Snackbar
      style={[{backgroundColor: snackbarSetting.variant == 'error' ? Colors.snackbarError : Colors.snackbarNormal}, Helpers.center, Helpers.row]}
      wrapperStyle={[{bottom: '7%'}]}
      visible={snackbarSetting.visible}
      duration={3000}
      onDismiss={() => dispatch(GlobalActions.snackbar({visible: false}))}>
      <View style={[Helpers.row, Helpers.crossCenter]}>
        <Text>{snackbarSetting.message}</Text>
      </View>
    </Snackbar>
  )
}

export default SnackbarCustom;


