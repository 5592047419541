/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { GlobalTypes } from './Actions'

export const dialogConfirm = (state, { settings }) => {
  let dialogConfirmData = state.dialogConfirm
  dialogConfirmData = {
    ...dialogConfirmData,
    ...settings,
  }


  if (!dialogConfirmData.visible) {
    dialogConfirmData = {
      ...dialogConfirmData,
      okAction: '',
      cancelAction: '',
      title: '',
      message: '',
      component: null,
      icon: null,
      loading: false,
    }
  }
  return {
    ...state,
    dialogConfirm: dialogConfirmData,
  }
}

export const snackbar = (state, { settings }) => {
  let snackbarSetting = state.snackbar
  snackbarSetting = {
    ...snackbarSetting,
    ...settings,
  }

  if (!snackbarSetting.visible) {
    snackbarSetting = {
      ...snackbarSetting,
      message: '',
      // variant: 'success',
      icon: null
    }
  }
  return {
    ...state,
    snackbar: snackbarSetting,
  }
}

export const dialogPhoto = (state, { settings }) => {
  return {
    ...state,
    dialogPhoto: {
      ...state.dialogPhoto,
      loading: false,
      ...settings
    }
  }
}

export const uploadFileRequest = (state) => {
  return {
    ...state,
    uploadFile:{
      loading: true,
      loaded: false,
      data: {}
    }
  }
}

export const uploadFileSuccess = (state, {res}) => {
  return {
    ...state,
    uploadFile:{
      loading: false,
      loaded: true,
      data: res
    }
  }
}

export const uploadFileError = (state, {err}) => {
  return {
    ...state,
    uploadFile:{
      loading: false,
      loaded: false,
      data: err
    }
  }
}

export const setKeyboard = (state, { show }) => {
  return {
    ...state,
    keyboardShow: {
      show
    }
  }
}

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GlobalTypes.DIALOG_CONFIRM]: dialogConfirm,
  [GlobalTypes.SNACKBAR]: snackbar,
  [GlobalTypes.DIALOG_PHOTO]: dialogPhoto,
  [GlobalTypes.SET_KEYBOARD]: setKeyboard
})
