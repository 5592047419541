/**
 * The initial values for the redux state.
 */

export const INITIAL_STATE = {
  dialogConfirm: {
    confirmBol: true,
    visible: false,
    title: '',
    message: '',
    component: null,
    content: null,
    okText: '是',
    loading: false,
    cancelText: '取消',
    okAction: '',
    cancelAction: '',
    icon: null,
  },
  snackbar: {
    visible: false,
    variant: 'success',
    duration: 3000,
    message: ''
  },
  dialogPhoto:{
    visible: false,
    result: {}
  },
  keyboardShow: {
    show: false
  }
}
