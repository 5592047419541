import React, { useEffect } from 'react'
import { DefaultTheme, Provider as PaperProvider, Portal, Button } from 'react-native-paper'
import NavigationService from '../../Services/NavigationService'
import AppNavigator from '../../Navigators/AppNavigator'
import { View } from 'react-native'
import StartupActions from '../../Stores/Startup/Actions'
import { Helpers } from '../../Theme'
import { useDispatch } from 'react-redux'
import Dialog from '../../Components/Dialog';
import Snackbar from '../../Components/Snackbar';

const theme = {
  ...DefaultTheme,
  dark: true,
  colors: {
    ...DefaultTheme.colors,
    primary: '#cccccc',
    background: '#1b2533',
    text: '#ffffff',
    error: 'red'
  }
};

const RootScreen = () => {
  // Deep linking prefix
  const prefix = 'boilerplate://';
  
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(StartupActions.startup())
  }, [])

  return (
    <PaperProvider theme={theme}>
      <View style={Helpers.fill}>
        <AppNavigator />
        <Dialog />
        <Snackbar />
      </View>
    </PaperProvider>
  )
}

export default RootScreen
