/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  verify: {
    loading: false,
    loaded: false,
    data: {}
  },
  redeem: {
    loading: false,
    loaded: false,
    data: {}
  }
}
