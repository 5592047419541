/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { RedeemTypes } from './Actions'

export const verify = (state) => ({
  ...state,
  verify: {
    loading: true,
    loaded: false,
    data: {}
  }
})

export const verifySuccess = (state, { res }) => ({
  ...state,
  verify: {
    loading: false,
    loaded: true,
    data: res.data
  }
})

export const verifyFailure = (state, { errorMessage }) => ({
  ...state,
  verify: {
    loading: false,
    loaded: false,
    data: {}
  }
})

export const redeem = (state) => ({
  ...state,
  redeem: {
    loading: true,
    loaded: false,
    data: {}
  }
})

export const redeemSuccess = (state, { res }) => ({
  ...state,
  redeem: {
    loading: false,
    loaded: true,
    data: res.data
  }
})

export const redeemFailure = (state, { errorMessage }) => ({
  ...state,
  redeem: {
    loading: false,
    loaded: false,
    data: {}
  }
})

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [RedeemTypes.VERIFY]: verify,
  [RedeemTypes.VERIFY_SUCCESS]: verifySuccess,
  [RedeemTypes.VERIFY_FAILURE]: verifyFailure,
  [RedeemTypes.REDEEM]: redeem,
  [RedeemTypes.REDEEM_SUCCESS]: redeemSuccess,
  [RedeemTypes.REDEEM_FAILURE]: redeemFailure,
})
