import React from 'react'
import { ActivityIndicator, ImageBackground, Text, TouchableOpacity, View } from 'react-native'
import { Helpers, Metrics } from '../../Theme'
import { Button } from 'react-native-paper'
import btnBg from '../../Assets/Images/btn_bg@3x.png'
import RedeemActions from '../../Stores/Redeem/Actions'
import { useDispatch, useSelector } from 'react-redux'
import NavigationService from '../../Services/NavigationService'
import AsyncStorage from '@react-native-community/async-storage'
import mbBackground from '../../Assets/Images/loadingbg.png'

/**
 * This is an example of a container component with a deep link.
 */
const TicketScreen = ({ route }) => {
  const { params } = route
  const dispatch = useDispatch()
  const redeem = useSelector((state) => {
    return state.redeem.redeem
  })
  const onSubmit = async () => {
    var verification = await AsyncStorage.getItem('verification')
    dispatch(
      RedeemActions.redeem({ qrCodeStrings: [params.qrCodeString], verifyCode: verification })
    )
  }

  return (
    <ImageBackground
      imageStyle={{
        resizeMode: 'cover',
      }}
      source={mbBackground}
      style={Helpers.fillColMain}
    >
      <View style={[Helpers.fillCenter, Metrics.mediumHorizontalMargin]}>
        <Text style={{ color: '#fff', fontSize: 20, fontWeight: 500, marginBottom: 15 }}>
          {params.title}
        </Text>
        <Text style={{ color: '#fff', marginBottom: 30, fontSize: 16 }}>
          貴賓 {params.user.name}
        </Text>
        <ImageBackground
          imageStyle={{
            resizeMode: 'cover',
            borderRadius: 4,
            opacity: redeem.loading ? 0.6 : 1,
          }}
          source={btnBg}
          style={[Helpers.rowCenter, { width: '100%', height: 38, marginBottom: 15 }]}
        >
          <TouchableOpacity
            disabled={redeem.loading}
            style={[Helpers.fillRowCenter, { height: 38 }]}
            onPress={onSubmit}
          >
            {redeem.loading && (
              <ActivityIndicator style={{ marginRight: 8 }} color="#fff" size="small" />
            )}
            <View style={Helpers.rowCenter}>
              <Text style={{ color: '#fff', fontSize: 14 }}>確認核銷</Text>
            </View>
          </TouchableOpacity>
        </ImageBackground>
        <Button
          disabled={redeem.loading}
          mode="outlined"
          onPress={() => {
            NavigationService.navigateAndReset('Camera')
          }}
          style={{ width: '100%' }}
          labelStyle={{ color: '#fff' }}
        >
          取消
        </Button>
      </View>
    </ImageBackground>
  )
}

export default TicketScreen
