import React from 'react'
import { ActivityIndicator, ImageBackground, Text, TouchableOpacity, View } from 'react-native'
import { Helpers, Metrics } from '../../Theme'
import { Button } from 'react-native-paper'
import btnBg from '../../Assets/Images/btn_bg@3x.png'
import { useDispatch, useSelector } from 'react-redux'
import NavigationService from '../../Services/NavigationService'
import AsyncStorage from '@react-native-community/async-storage'
import mbBackground from '../../Assets/Images/loadingbg.png'
import { redeemService } from '../../Services/RedeemService'
import GlobalActions from '../../Stores/Global/Actions'

/**
 * This is an example of a container component with a deep link.
 */
const TicketByCodeScreen = ({ route }) => {
  const { params } = route
  const dispatch = useDispatch()
  const redeem = useSelector((state) => {
    return state.redeem.redeem
  })
  const onSubmit = async () => {
    const eventId = await AsyncStorage.getItem('eventId')
    const verifyCode = await AsyncStorage.getItem('verification')
    redeemService
      .redeemByCode({ code: params.code, eventId, verifyCode })
      .then(() => {
        dispatch(
          GlobalActions.snackbar({
            visible: true,
            variant: 'success',
            message: '核銷成功',
          })
        )
        NavigationService.navigateAndReset('Camera')
      })
      .catch((error) => {
        let errorCode = null
        if (error.status === 404) {
          dispatch(
            GlobalActions.snackbar({
              visible: true,
              variant: 'error',
              message: '票券核銷碼輸入錯誤，請重新輸入',
            })
          )
          return
        }
        if (error.data.errors) {
          errorCode = error.data.errors[0].title
        }
        if (errorCode === 'error.events-tickets.already-used') {
          dispatch(
            GlobalActions.snackbar({
              visible: true,
              variant: 'error',
              message: '核銷失敗，請勿重複核銷',
            })
          )
          return
        }
        if (errorCode === 'error.events-tickets.verify-code-invalid') {
          dispatch(
            GlobalActions.dialogConfirm({
              confirmBol: false,
              visible: true,
              title: '核銷失敗',
              okText: '好的',
              okAction: (dispatch) => {
                dispatch(GlobalActions.dialogConfirm({ visible: false }))
                NavigationService.navigateAndReset('Camera')
              },
              message: '請確認貴賓活動票券資訊，協助出示正確票券，或引導至正確活動場地',
              component: null,
            })
          )
          return
        }
        dispatch(
          GlobalActions.snackbar({
            visible: true,
            variant: 'error',
            message: '驗證系統異常',
          })
        )
      })
  }

  return (
    <ImageBackground
      imageStyle={{
        resizeMode: 'cover',
      }}
      source={mbBackground}
      style={Helpers.fillColMain}
    >
      <View style={[Helpers.fillCenter, Metrics.mediumHorizontalMargin]}>
        <Text style={{ color: '#fff', fontSize: 20, fontWeight: 500, marginBottom: 15 }}>
          {params.title}
        </Text>
        <Text style={{ color: '#fff', marginBottom: 30, fontSize: 16 }}>
          貴賓 {params.username}
        </Text>
        <ImageBackground
          imageStyle={{
            resizeMode: 'cover',
            borderRadius: 4,
            opacity: redeem.loading ? 0.6 : 1,
          }}
          source={btnBg}
          style={[Helpers.rowCenter, { width: '100%', height: 38, marginBottom: 15 }]}
        >
          <TouchableOpacity
            disabled={redeem.loading}
            style={[Helpers.fillRowCenter, { height: 38 }]}
            onPress={onSubmit}
          >
            {redeem.loading && (
              <ActivityIndicator style={{ marginRight: 8 }} color="#fff" size="small" />
            )}
            <View style={Helpers.rowCenter}>
              <Text style={{ color: '#fff', fontSize: 14 }}>確認核銷</Text>
            </View>
          </TouchableOpacity>
        </ImageBackground>
        <Button
          disabled={redeem.loading}
          mode="outlined"
          onPress={() => {
            NavigationService.navigateAndReset('Camera')
          }}
          style={{ width: '100%' }}
          labelStyle={{ color: '#fff' }}
        >
          取消
        </Button>
      </View>
    </ImageBackground>
  )
}

export default TicketByCodeScreen
