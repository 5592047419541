import React from 'react'
import { Text, View, ImageBackground } from 'react-native'
import styles from './SplashScreenStyle'
import { Helpers } from '../../Theme'
import mbBackground from '../../Assets/Images/loadingbg.png'

const SplashScreen = () => (
  <ImageBackground imageStyle={{
    resizeMode: 'cover'
  }} source={mbBackground} style={Helpers.fillColMain}>
    
  </ImageBackground>
)

export default SplashScreen
