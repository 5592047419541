/**
 * This is an example of a service that connects to a 3rd party API.
 *
 * Feel free to remove this example from your application.
 */

import { Permissions } from 'react-native-unimodules';

const getLocation = async () => {
    const { status } = await Permissions.askAsync(Permissions.LOCATION)
    if (status == 'granted') {
        return true
    } else {
        return false
    }
}

const getMotion = async () => {
    const { status } = await Permissions.askAsync(Permissions.MOTION)
    if (status == 'granted') {
        return true
    } else {
        return false
    }
}

const getCamera = async () => {
    const camera = await Permissions.askAsync(Permissions.CAMERA)
    const cameraRoll = await Permissions.askAsync(Permissions.CAMERA_ROLL)

    if (camera.status == 'granted' && cameraRoll.status == 'granted') {
        return true
    } else {
        return false
    }
}

const getCameraSingle = async () => {
    const camera = await Permissions.askAsync(Permissions.CAMERA)

    if (camera.status == 'granted') {
        return true
    } else {
        return false
    }
}

export default {
    getLocation,
    getMotion,
    getCamera,
    getCameraSingle,
}