import React, { useState } from 'react'
import {
  ActivityIndicator,
  ImageBackground,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import { ApplicationStyles, Helpers, Metrics } from '../../Theme'
import { useDispatch, useSelector } from 'react-redux'
import btnBg from '../../Assets/Images/btn_bg@3x.png'
import RedeemActions from '../../Stores/Redeem/Actions'
import mbBackground from '../../Assets/Images/loadingbg.png'

/**
 * This is an example of a container component with a deep link.
 */
const VerificationScreen = () => {
  const dispatch = useDispatch();
  const [eventId, setEventId] = useState('');
  const [verification, setVerification] = useState('');
  const verify = useSelector((state) => {
    return state.redeem.verify
  });
  const onEventInputChange = (eventStr) => {
    setEventId(eventStr);
  }
  const onVerificationInputChange = (verificationStr) => {
    setVerification(verificationStr);
  }
  const setAsyncStorage = async () => {
    dispatch(RedeemActions.verify({ identifier: eventId, verifyCode: verification }))
  }

  return (<ImageBackground imageStyle={{
    resizeMode: 'cover'
  }} source={mbBackground} style={Helpers.fillColMain}>
    <View style={[Helpers.fillColMain, Metrics.mediumHorizontalMargin]}>
      <Text style={{ fontSize: 18, marginBottom: 15, color: '#fff' }}>
        活動編號:
      </Text>
      <TextInput
        autoCapitalize='none'
        placeholder="請輸入活動編號"
        style={[ApplicationStyles.textInput, { marginBottom: 15 }]}
        value={eventId}
        onChangeText={onEventInputChange}
      />
      <Text style={{ fontSize: 18, marginBottom: 15, color: '#fff'}}>
        活動驗證碼:
      </Text>
      <TextInput
        autoCapitalize='none'
        placeholder="請輸入驗證碼"
        style={[ApplicationStyles.textInput, {marginBottom: 25}]}
        value={verification}
        onChangeText={onVerificationInputChange}
      />
      <ImageBackground imageStyle={{
        resizeMode: 'cover',
        borderRadius: 4,
        opacity: (verify.loading) ? 0.6 : 1
      }} source={btnBg} style={[Helpers.rowCenter, { width: '100%', height: 38 }]}>
        <TouchableOpacity disabled={verify.loading} style={[Helpers.fillRowCenter, { height: 38 }]} onPress={() => { setAsyncStorage() }}>
          {verify.loading && <ActivityIndicator style={{ marginRight: 8 }} color="#fff" size="small" />}
          <View style={Helpers.rowCenter}>
            <Text style={{ color: '#fff', fontSize: 14 }}>送出</Text>
          </View>
        </TouchableOpacity>
      </ImageBackground>
    </View>
    <View style={[Helpers.center, { paddingBottom: 15}]}>
      <Text style={{ color: '#fff' }}>v 0.2.1</Text>
    </View>
  </ImageBackground>)
}

export default VerificationScreen
