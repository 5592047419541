import { put, call } from 'redux-saga/effects'
import GlobalActions from '../Stores/Global/Actions';
import RedeemActions from '../Stores/Redeem/Actions'
import { redeemService } from '../Services/RedeemService'
import AsyncStorage from '@react-native-community/async-storage';
import NavigationService from '../Services/NavigationService'

/**
 * A saga can contain multiple functions.
 *
 * This example saga contains only one to fetch fake user informations.
 * Feel free to remove it.
 */
export function* verify({ identifier, verifyCode }) {
  try {
    const res = yield call(redeemService.verify, { identifier, verifyCode })
    yield put(RedeemActions.verifySuccess({ res: res }))
    yield put(GlobalActions.snackbar({
      visible: true,
      variant: 'success',
      message: '驗證成功'
    }))
    yield call(AsyncStorage.setItem, 'verification', verifyCode);
    yield call(AsyncStorage.setItem, 'eventName', res.data.name);
    yield call(AsyncStorage.setItem, 'eventId', res.data.eventId);
    NavigationService.navigateAndReset('Camera')
  } catch (error) {
    console.log(error)
    yield put(GlobalActions.snackbar({
      visible: true,
      variant: 'error',
      message: '驗證失敗'
    }))
    yield put(RedeemActions.verifyFailure({ errorMessage: error }))
  }
}

export function* redeem({ qrCodeStrings, verifyCode}) {
  try {
    const res = yield call(redeemService.redeem, { qrCodeStrings, verifyCode })
    yield put(RedeemActions.redeemSuccess({ res: res }))
    yield put(GlobalActions.snackbar({
      visible: true,
      variant: 'success',
      message: '核銷成功'
    }))
    NavigationService.navigateAndReset('Camera')
  } catch (error) {
    if (error.data.errors[0].title == 'error.events-tickets.already-used') {
      yield put(GlobalActions.snackbar({
        visible: true,
        variant: 'error',
        message: '核銷失敗，請勿重複核銷'
      }))
    }
    else if (error.data.errors[0].title == 'error.events-tickets.qr-code-invalid') {
      yield put(
        GlobalActions.dialogConfirm({
          confirmBol: false,
          visible: true,
          title: '核銷失敗',
          okText: '重新掃描',
          okAction: (dispatch) => {
            dispatch(GlobalActions.dialogConfirm({ visible: false }))
            NavigationService.navigateAndReset('Camera')
          },
          message: '請確認貴賓活動票券資訊，協助出示正確票券，或引導至正確活動場地',
          component: null
        })
      )
    }
    else if (error.data.errors[0].title == 'error.events-tickets.verify-code-invalid') {
      yield put(
        GlobalActions.dialogConfirm({
          confirmBol: false,
          visible: true,
          title: '核銷失敗',
          okText: '重新掃描',
          okAction: (dispatch) => {
            dispatch(GlobalActions.dialogConfirm({ visible: false }))
            NavigationService.navigateAndReset('Camera')
          },
          message: '請確認貴賓活動票券資訊，協助出示正確票券，或引導至正確活動場地',
          component: null
        })
      )
    }
    else {
      yield put(GlobalActions.snackbar({
        visible: true,
        variant: 'error',
        message: '驗證系統異常'
      }))
    }

    yield put(RedeemActions.redeemFailure({ errorMessage: error }))
  }
}
