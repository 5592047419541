import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Image } from 'react-native'
import { Helpers, Colors, Fonts, Metrics } from '../Theme'
import { Provider as PaperProvider, Dialog, Button, Text } from 'react-native-paper'
import GlobalActions from '../Stores/Global/Actions'

const DialogCustom = () => {
  const dispatch = useDispatch();
  const dialogConfirm = useSelector((state) => state.global.dialogConfirm);

  return (
    <Dialog  
      style={{paddingHorizontal: 14}}
      visible={dialogConfirm.visible}
      dismissable={() => dispatch(GlobalActions.dialogConfirm({visible: false}))} 
    >
      {
        dialogConfirm.title != '' &&
        <Dialog.Title style={{margin: 0}}>
          <View style={{paddingVertical: 14, borderBottomWidth: 1, borderBottomColor: '#dddddd', width: '100%'}}>
            <Text style={[Fonts.normal, { fontSize: 28, color: 'rgb(51,51,51)', textAlign: 'center' }]}>{dialogConfirm.title}</Text>
          </View>
        </Dialog.Title>
      }
      
      {
        dialogConfirm.component &&
        <View>{dialogConfirm.component}</View>
      }
      {
        dialogConfirm.content &&
        <View>{dialogConfirm.content}</View>
      }
      {
        !dialogConfirm.component && !dialogConfirm.content &&
        <Dialog.Content style={{paddingTop: 0,paddingHorizontal: 0, paddingVertical: 0, minHeight: 152, justifyContent: 'center'}}>
          <View style={[Helpers.center]}>
            {
              dialogConfirm.icon &&
              <Image source={dialogConfirm.icon} style={{width: 20, height: 20, marginBottom: 18}}/>
            }
            <Text style={[Fonts.normal,{ color: '#000' }]}>{dialogConfirm.message}</Text>
          </View>
        </Dialog.Content>
      }
      {
        !dialogConfirm.component &&
        <Dialog.Actions style={{padding: 0, borderTopWidth: 1, borderTopColor: '#dddddd'}}>
          {dialogConfirm.confirmBol &&
            <View style={{flex: 1, height: 55, justifyContent: 'center'}}>
              <Button 
              loading={dialogConfirm.loading} 
              disabled={dialogConfirm.loading} 
              labelStyle={{color: 'rgba(0, 0, 0, 0.6)', fontSize: 18, fontWeight: 400}} 
              onPress={() => {
                if (typeof dialogConfirm.cancelAction === 'function') {
                  dialogConfirm.cancelAction(dispatch)
                } else {
                  dispatch(GlobalActions.dialogConfirm({visible: false}))
                }
              }}
              >{`${dialogConfirm.cancelText}`}</Button>
            </View>
          }
          
          <View style={{flex: 1, height: 55, justifyContent: 'center'}}>
            <Button  
              loading={dialogConfirm.loading} 
              disabled={dialogConfirm.loading}
              labelStyle={{color: 'rgb(41,116,168)', fontSize: 18, fontWeight: 400}}
              onPress={() => {
                if (typeof dialogConfirm.okAction === 'function') {
                  dialogConfirm.okAction(dispatch)
                } else {
                  dispatch(GlobalActions.dialogConfirm({visible: false}))
                }
              }}
            >{`${dialogConfirm.okText}`}</Button>
          </View>
          
        </Dialog.Actions>
      }
      
    </Dialog>
  )
}

export default DialogCustom;