import axios from 'axios'
import { Config } from '../Config'

/**
 * This is an example of a service that connects to a 3rd party API.
 *
 * Feel free to remove this example from your application.
 */
const redeemApiClient = axios.create({
  /**
   * Import the config from the App/Config/index.js file
   */
  baseURL: Config.API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 3000,
})

function verify({ identifier, verifyCode }) {
  return redeemApiClient
    .post('/v1/event:verify', {
      identifier,
      verifyCode,
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error.response
    })
}

function redeem({ qrCodeStrings, verifyCode }) {
  return redeemApiClient
    .post('/v1/tickets:redeem', {
      qrCodeStrings,
      verifyCode,
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error.response
    })
}

function redeemByCode({ code, eventId, verifyCode }) {
  return redeemApiClient
    .post('/v1/tickets:redeem-by-code', {
      code,
      eventId,
      verifyCode,
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error.response
    })
}

function getUserByCode({ eventId, code }) {
  return redeemApiClient
    .post('/v1/tickets:get-user', {
      eventId,
      code,
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error.response
    })
}

export const redeemService = {
  verify,
  redeem,
  redeemByCode,
  getUserByCode,
}
